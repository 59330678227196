import request from 'app/http'

// 任务统计-任务状态统计
const getPersonalviewStatus = params => {
  return request.get('/stats/personalview/status/', { params })
}

// 任务统计-任务类型占比
const getPersonalviewType = params => {
  return request.get('/stats/personalview/type/', { params })
}

// 任务统计-裁定结果占比
const getPersonalviewAnalysisresult = params => {
  return request.get('/stats/personalview/analysisresult/', { params })
}

// 任务统计-恶意检出任务趋势
const getPersonalviewThreattrend = params => {
  return request.get('/stats/personalview/threattrend/', { params })
}

// 任务统计-任务提交趋势
const getPersonalviewSubmittrend = params => {
  return request.get('/stats/personalview/submittrend/', { params })
}

// 任务统计-命中标签
const getPersonalviewTags = params => {
  return request.get('/stats/personalview/tags/', { params })
}

// 文件类型、恶意文件类型、行为特征分布、分析环境统计
const getPersonalviewStatis = params => {
  return request.get('/stats/personalview/statis/', { params })
}

// 各类型威胁top 10
const getPersonalviewTopten = params => {
  return request.get('/stats/personalview/top/tag', { params })
}
export {
  getPersonalviewStatus,
  getPersonalviewType,
  getPersonalviewAnalysisresult,
  getPersonalviewThreattrend,
  getPersonalviewTags,
  getPersonalviewSubmittrend,
  getPersonalviewStatis,
  getPersonalviewTopten
}
